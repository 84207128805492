
import _ from "lodash";
import {
  defineComponent,
  ref,
  onMounted,
  reactive,
  onBeforeMount,
  onActivated,
  watch,
} from "vue";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import store, { MutationNames } from "@/store/index";
import { getBooks } from "@/services";
import JsBridge from "../../../jsBridge";
import servers from "@/config/servers";
import config from "@/config/app.config";
import { eventCenter, event } from "@evideo/frontend-utils";
import logger from "@evideo/logger";

export default defineComponent({
  name: "TeachMaterialCartoon",
  props: {
    title: String,
  },

  setup() {
    const { registerH5GetToken } = JsBridge();
    const router = useRouter();
    let message = ref("");
    // 当前页码
    let teachMaterialNum = ref(1);
    // 总页码
    let teachMaterialSumNum = ref(1);
    let teachMaterialList = reactive([]);
    let teachMaterialeight = reactive([]);
    let firstLoadType = ref(true);
    const device = navigator.userAgent;
    const android =
      device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;
    let watchType = ref(false);
    watch(
      () => store.state.reloadTeachMater,
      (newVal) => {
        if (watchType.value) {
          if (newVal) {
            // teachMaterialSumNum.value = 1;
            // teachMaterialNum.value = 1;
            // teachMaterialList.length = 0;
            // teachMaterialeight.length = 0;
            getBookInfo();
            store.commit(MutationNames.CHANGE_RELOADTEACHMATER, false);
            logger.info(
              "重新获取数据之后状态修改-》",
              store.state.reloadTeachMater
            );
          } else {
            logger.info("newVal", newVal);
            logger.info("reloadTeachMater", store.state.reloadTeachMater);
          }
        } else {
          logger.info("watchType", watchType.value);
        }
      }
    );
    // //新版本不用这个去获取数据
    // setTimeout(() => {
    //   if (!window.WebViewJavascriptBridge) {
    //     logger.info("WebViewJavascriptBridge未注册");
    //     return;
    //   }
    //   window.WebViewJavascriptBridge.callHandler(
    //     "h5GetToken", // native中注册时的key值
    //     null, // native中注册方法中的传入的数据
    //     function (responseData) {
    //       const parseData = JSON.parse(responseData);
    //       const androidToken = parseData.data.token;
    //       logger.info("androidToken", androidToken);
    //       store.commit(MutationNames.CHANGE_TOKEN, androidToken);
    //       teachMaterialSumNum.value = 1;
    //       teachMaterialNum.value = 1;
    //       getBookInfo();
    //       teachMaterialList.length = 0;
    //       teachMaterialeight.length = 0;
    //     }
    //   );
    // }, 800);

    // const androidToken =
    //   // "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vY29tbW9uLXNlcnZpY2UvYXV0aC9sb2dpbiIsImlhdCI6MTYzNDIwNTc1MywiZXhwIjoxNjY5NzM3NzUzLCJuYmYiOjE2MzQyMDU3NTMsImp0aSI6IkNSQ0lIaFBYb2lCUUExTFkiLCJzdWIiOjE3NywicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyJ9.iRhmc0KRXTThBiY5X4WfulAgTOt5pLQpKhCEhUX_ZZg";
    //   // "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vY29tbW9uLXNlcnZpY2UvYXV0aC9sb2dpbiIsImlhdCI6MTYzNDAyMTM4NCwiZXhwIjoxNjM0NjI2MTg0LCJuYmYiOjE2MzQwMjEzODQsImp0aSI6IlZHdHZFRU1nNlpZS1N2MHYiLCJzdWIiOjEyMywicHJ2IjoiYjI3YmVlMjNiYWY1NDI5ZjViOWEyMTY5NmZkZTAzYzI3NzA0NGVhNSJ9.wqY29o1JSdRT5I1ZIUjhB_znJvwgvoAwrjuaELi8l6o";
    //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vY29tbW9uLXNlcnZpY2UvYXV0aC9sb2dpbiIsImlhdCI6MTYzNjAxNDA2MCwiZXhwIjoxNjcxNTQ2MDYwLCJuYmYiOjE2MzYwMTQwNjAsImp0aSI6Iklkem9Ic1dsOWdSTUFSN2wiLCJzdWIiOjEyMywicHJ2IjoiYjI3YmVlMjNiYWY1NDI5ZjViOWEyMTY5NmZkZTAzYzI3NzA0NGVhNSJ9.1ThHGh2vvVn3PMb3FYSI-pOcqhKP8-k2LkJCSk230jY";
    // localStorage.setItem("token", androidToken);
    // store.commit(MutationNames.CHANGE_TOKEN, androidToken);

    const getBookInfo = () => {
      logger.info("重新获取数据");
      teachMaterialSumNum.value = 1;
      teachMaterialNum.value = 1;
      teachMaterialList.length = 0;
      teachMaterialeight.length = 0;
      getBooks({ per_page: 1000 }).then((data) => {
        teachMaterialList = data.data?.items;
        teachMaterialeight.push(...teachMaterialList.slice(0, 8));
        teachMaterialSumNum.value = Math.ceil(teachMaterialList.length / 8);
      });
    };
    onBeforeMount(async () => {
      if (store.state.firstLoadPPT) {
        loadPPT();
        store.commit(MutationNames.CHANGE_FIRSTLOADPPT, false);
      }
      // 接收上级应用 预加载完成，可以卸载应用了
      eventCenter.sub("teachPptPC-preloadOk", () => {
        logger.info("收到");
        // console.timeEnd("开始");
        eventCenter.send(event.COMMON_UNLOAD_MICRO_APP, { app: "teachPptPC" });
      });
      logger.info("教材列表", localStorage.getItem("token"));
      // getBookInfo();
      // 新版本用这个去获取数据
      const timer = setInterval(() => {
        // 判断文档和所有子资源(图片、音视频等)已完成加载
        if (document.readyState === "complete") {
          logger.info("web加载完成");
          window.clearInterval(timer);
          registerH5GetToken().then((data) => {
            logger.info("安卓调用完成", data);
            watchType.value = true;
            getBookInfo();
          });
        }
      }, 500);
    });
    onMounted(() => {
      // localStorage.setItem("token", testToken);
      // store.commit(MutationNames.CHANGE_TOKEN, testToken);
      // teachMaterialeight.push(...teachMaterialList.slice(0, 8));
      setTimeout(() => {
        if (firstLoadType.value) {
          firstLoadType.value = false;
          router.push({
            path: "/materialPpt",
            query: { firstLoadType: false },
          });
        }
      }, 800);
    });

    onActivated(() => {
      if (android) {
        logger.info("通知android显示");
        window.WebViewJavascriptBridge.callHandler(
          "loadSuccess", // native中注册时的key值
          null // native中注册方法中的传入的数据
        );
      }
    });
    const loadPPT = async () => {
      logger.info("预加载");
      if (!window.qiankunStarted) {
        window.qiankunStarted = true;
      }
      const env = JSON.stringify(process.env.RELEASE_ENV);
      // let entryUrl = "http://192.168.74.175:8090/";
      let entryUrl = config[servers.pptist];
      if (env === `"devp"` && localStorage.getItem("entryUrl") !== null) {
        entryUrl = localStorage.entryUrl;
      }
      eventCenter.send(event.COMMON_LOAD_MICRO_APP, {
        app: "teachPptPC",
        el: "#ppt-preload",
        entry: entryUrl,
        args: {
          token: store.state.token,
          appName: "teachPptPC",
          preload: true,
        },
        configuration: { $$cacheLifecycleByAppName: true },
      });
    };
    const next = () => {
      logger.info("下一页");
      const endNum = Math.ceil(teachMaterialList.length / 8);
      if (teachMaterialNum.value === endNum) {
        message.value = "已经是最后一页";
        ElMessage.warning({
          offset: 200,
          message: message.value,
          type: "warning",
        });
      } else {
        teachMaterialNum.value++;
        teachMaterialeight.length = 0;
        const startIndex = teachMaterialNum.value * 8 - 8;
        const endIndex = teachMaterialNum.value * 8;
        teachMaterialeight.push(
          ...teachMaterialList.slice(startIndex, endIndex)
        );
      }
    };
    const previous = () => {
      logger.info("上一页");
      if (teachMaterialNum.value === 1) {
        message.value = "已经是第一页";
        ElMessage.warning({
          offset: 200,
          message: message.value,
          type: "warning",
        });
      } else {
        teachMaterialNum.value--;
        teachMaterialeight.length = 0;
        const startIndex = teachMaterialNum.value * 8 - 8;
        const endIndex = teachMaterialNum.value * 8;
        teachMaterialeight.push(
          ...teachMaterialList.slice(startIndex, endIndex)
        );
      }
    };
    const btnBack = _.throttle(() => {
      logger.info("我已经触发返回的方法了");
      teachMaterialSumNum.value = 1;
      teachMaterialNum.value = 1;
      getBooks({ per_page: 1000 }).then((data) => {
        teachMaterialList = data.data.items;
        teachMaterialeight.push(...teachMaterialList.slice(0, 8));
        teachMaterialSumNum.value = Math.ceil(teachMaterialList.length / 8);
      });
      teachMaterialList.length = 0;
      teachMaterialeight.length = 0;

      // logger.info("我要调安卓方法了");
      // window.WebViewJavascriptBridge.callHandler(
      //   "closeTeachMaterial", // native中注册时的key值
      //   null // native中注册方法中的传入的数据
      // );
      // 现在不在该页面向Android去发送隐藏的方法，改为到blank页面去发送
      router.replace({
        path: "/blank",
        query: {
          skinType: 0,
        },
      });
    }, 5000);
    const goToMaterialPpt = (id, url, name) => {
      router.push({
        path: "/materialPpt",
        query: {
          bookId: id,
          url,
          name: encodeURI(name),
          firstLoadType: false,
          // 1108 经确认机顶盒教材列表只获取官方教材不获取个人教材
          teachMaterialType: "official",
        },
      });
    };
    return {
      teachMaterialeight,
      teachMaterialList,
      message,
      teachMaterialNum,
      teachMaterialSumNum,
      next,
      previous,
      btnBack,
      goToMaterialPpt,
    };
  },
});
